/* Exo thin font from Google. */
@import url(https://fonts.googleapis.com/css?family=Exo:100);
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@import "./variables.modules";

/* Mixin for dot animation */
@mixin dots($count) {
  $text-shadow: ();
  @for $i from 0 through $count {
    $text-shadow: $text-shadow,
                 (-.5+(random()) * 3) + em
                 (-.5+(random()) * 3) + em
                 7px
                 hsla(random() * 360, 100%, 50%,.9);
  }
  text-shadow: $text-shadow;
}

/* Animation for dot movement */
@keyframes move {
  from {
    transform: rotate(0deg) scale(12) translateX(-20px);
  }
  to {
    transform: rotate(360deg) scale(18) translateX(20px);
  }
}

html {
  background: #123;
}

body {
    font-family: 'Roboto', Helvetica, sans-serif;
    font-size: 1.5vh;
    width: 100%;
    height: 100%;
    color: $dark;
    background-color: $white;
    box-sizing: border-box;
    position: relative;
    overflow: auto;
}

body, head {
  color: transparent;
}

head::before, head::after,
body::before, body::after {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 3em;
  height: 3em;
  content: '.';
  mix-blend-mode: screen;
  animation: 44s -27s move infinite ease-in-out alternate;
  z-index: 0;
}

body::before {
  @include dots(40);
  animation-duration: 44s;
  animation-delay: -27s;
}

body::after {
  @include dots(40);
  animation-duration: 43s;
  animation-delay: -32s;
}

head::before {
  @include dots(40);
  animation-duration: 42s;
  animation-delay: -23s;
}

head::after {
  @include dots(40);
  animation-duration: 41s;
  animation-delay: -19s;
}

li {
    color: $dark;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a,
a:link,
a:hover,
a:visited,
a:active {
    text-decoration: none;
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
}

/* Additional styles to ensure content appears on top of the background */
header, main, footer {
    position: relative;
    z-index: 1;
}
